import {
    IActionsBE,
    IActionsFE,
    IFlowsBE,
    IFlowsFE,
    IGetALlShippings,
    IGetALlShippingsSdor,
    IGetAllShippingsGeneric,
    IOverview118WithPermissions,
    IOverviewDiabWithPermissions,
    IOverviewDmWithPermissions,
    IOverviewFarmWithPermissions,
    IOverviewFarmospWithPermissions,
    IOverviewFarmterWithPermissions,
    IOverviewFimWithPermissions,
    IOverviewLaboWithPermissions,
    IOverviewPatologyWithPermissions,
    IOverviewRecoveryWithPermissions,
    IOverviewSpaWithPermissions,
    IOverviewSpsWithPermissions,
    IPendingShippingsGenericWithPermissionsFE,
    IPendingShippingsSdorWithPermissionsFE,
    IPendingShippingsWithPermissionsFE,
    IRoleBE,
    IRoleFE,
    IStructureBE,
} from '../model';

import { getNegativeRandom } from '.';
import { normalizeUseRecoveryOverview } from './normilizer';

export const deserializeRoles = (roles?: IRoleBE[]): IRoleFE[] => {
    if (Array.isArray(roles)) {
        return roles.map(r => deserializeRole(r));
    } else {
        return [];
    }
};
export const deserializeRole = (role: IRoleBE): IRoleFE => ({
    nameDe: role.nameDe,
    nameIt: role.nameIt,
    id: role.id,
});
export const deserializeActions = (actions?: IActionsBE[]): IActionsFE[] => {
    if (Array.isArray(actions)) {
        return actions.map(a => deserializeAction(a));
    } else {
        return [];
    }
};
export const deserializeAction = (role: IActionsBE): IActionsFE => ({
    nameDe: role.descriptionDe,
    nameIt: role.descriptionIt,
    id: role.id,
});
export const deserializeFlows = (flows?: IFlowsBE[]): IFlowsFE[] => {
    if (Array.isArray(flows)) {
        return flows.map(a => deserializeFlow(a));
    } else {
        return [];
    }
};
export const deserializeFlow = (role: IFlowsBE): IFlowsFE => ({
    nameDe: role.nameDe,
    nameIt: role.nameIt,
    id: role.id,
});

export const deserializeStructures = (structures?: IStructureBE[]): IStructureBE[] => {
    if (Array.isArray(structures)) {
        return structures.sort((a, b) => a.structureCode - b.structureCode);
    } else {
        return [];
    }
};

export const deserializeGetAllShippings = (pendingShippings: IGetALlShippings | undefined): IPendingShippingsWithPermissionsFE => {
    if (!!pendingShippings && Array.isArray(pendingShippings.shippingDetails)) {
        const { permissionSet } = pendingShippings;
        return {
            permissions: { ...permissionSet },
            pendingShipping: pendingShippings.shippingDetails.map(data => {
                if (data.id === 0) return { ...data, id: getNegativeRandom() };
                else {
                    return data;
                }
            }),
        };
    } else {
        return {
            permissions: {
                canDownloadFiles: false,
                canDelete: false,
                canConfirm: false,
                canUpload: false,
                canSend: false,
                canDowloadLogSynthetic: false,
                canViewIconFiles: false,
                canExportOverview: false,
            },
            pendingShipping: [],
        };
    }
};

export const deserializeGetAllShippingsGeneric = (pendingShippings: IGetAllShippingsGeneric | undefined): IPendingShippingsGenericWithPermissionsFE => {
    if (!!pendingShippings && Array.isArray(pendingShippings.shippingDetails)) {
        const { permissionSet } = pendingShippings;
        return {
            permissions: { ...permissionSet },
            pendingShipping: pendingShippings.shippingDetails.map(data => {
                if (data.id === 0) return { ...data, id: getNegativeRandom() };
                else {
                    return data;
                }
            }),
        };
    } else {
        return {
            permissions: {
                canDownloadFiles: false,
                canDelete: false,
                canConfirm: false,
                canUpload: false,
                canSend: false,
                canDowloadLogSynthetic: false,
                canViewIconFiles: false,
                canExportOverview: false,
            },
            pendingShipping: [],
        };
    }
};
export const deserializeGetAllShippingsSdor = (pendingShippings: IGetALlShippingsSdor | undefined): IPendingShippingsSdorWithPermissionsFE => {
    if (!!pendingShippings && Array.isArray(pendingShippings.shippingDetails)) {
        const { permissionSet } = pendingShippings;
        return {
            permissions: { ...permissionSet },
            pendingShipping: pendingShippings.shippingDetails.map(data => {
                if (data.id === 0) return { ...data, id: getNegativeRandom() };
                else {
                    return data;
                }
            }),
        };
    } else {
        return {
            permissions: {
                canDownloadFiles: false,
                canDelete: false,
                canConfirm: false,
                canUpload: false,
                canSend: false,
                canDowloadLogSynthetic: false,
                canViewIconFiles: false,
                canExportOverview: false,
            },
            pendingShipping: [],
        };
    }
};

export const deserializeUseRecoveryOverview = (overviewResponse: IOverviewRecoveryWithPermissions | undefined): IOverviewRecoveryWithPermissions => {
    if (!!overviewResponse && Array.isArray(overviewResponse.overviewTableRows)) {
        const { permissionSet } = overviewResponse;
        return {
            overviewTableRows: normalizeUseRecoveryOverview(overviewResponse.overviewTableRows),
            permissionSet: { ...permissionSet },
        };
    } else {
        return {
            permissionSet: {
                canDownloadFiles: false,
                canDelete: false,
                canConfirm: false,
                canUpload: false,
                canSend: false,
                canDowloadLogSynthetic: false,
                canViewIconFiles: false,
                canExportOverview: false,
            },
            overviewTableRows: [],
        };
    }
};

export const deserializeUsePatologyOverview = (overviewResponse: IOverviewPatologyWithPermissions | undefined): IOverviewPatologyWithPermissions => {
    if (!!overviewResponse && Array.isArray(overviewResponse.overviewTableRows)) {
        const { permissionSet } = overviewResponse;
        return {
            overviewTableRows: overviewResponse.overviewTableRows,
            permissionSet: { ...permissionSet },
        };
    } else {
        return {
            permissionSet: {
                canDownloadFiles: false,
                canDelete: false,
                canConfirm: false,
                canUpload: false,
                canSend: false,
                canDowloadLogSynthetic: false,
                canViewIconFiles: false,
                canExportOverview: false,
            },
            overviewTableRows: [],
        };
    }
};

export const deserializeUseFimOverview = (overviewResponse: IOverviewFimWithPermissions): IOverviewFimWithPermissions => {
    if (!!overviewResponse && Array.isArray(overviewResponse.overviewTableRows)) {
        const { permissionSet } = overviewResponse;
        return {
            overviewTableRows: overviewResponse.overviewTableRows,
            permissionSet: { ...permissionSet },
        };
    } else {
        return {
            permissionSet: {
                canDownloadFiles: false,
                canDelete: false,
                canConfirm: false,
                canUpload: false,
                canSend: false,
                canDowloadLogSynthetic: false,
                canViewIconFiles: false,
                canExportOverview: false,
            },
            overviewTableRows: [],
        };
    }
};

export const deserializeUseDmOverview = (overviewResponse: IOverviewDmWithPermissions): IOverviewDmWithPermissions => {
    if (!!overviewResponse && Array.isArray(overviewResponse.overviewTableRows)) {
        const { permissionSet } = overviewResponse;
        return {
            overviewTableRows: overviewResponse.overviewTableRows,
            permissionSet: { ...permissionSet },
        };
    } else {
        return {
            permissionSet: {
                canDownloadFiles: false,
                canDelete: false,
                canConfirm: false,
                canUpload: false,
                canSend: false,
                canDowloadLogSynthetic: false,
                canViewIconFiles: false,
                canExportOverview: false,
            },
            overviewTableRows: [],
        };
    }
};

export const deserializeUseFarmOverview = (overviewResponse: IOverviewFarmWithPermissions): IOverviewFarmWithPermissions => {
    if (!!overviewResponse && Array.isArray(overviewResponse.overviewTableRows)) {
        const { permissionSet } = overviewResponse;
        return {
            overviewTableRows: overviewResponse.overviewTableRows,
            permissionSet: { ...permissionSet },
        };
    } else {
        return {
            permissionSet: {
                canDownloadFiles: false,
                canDelete: false,
                canConfirm: false,
                canUpload: false,
                canSend: false,
                canDowloadLogSynthetic: false,
                canViewIconFiles: false,
                canExportOverview: false,
            },
            overviewTableRows: [],
        };
    }
};

export const deserializeUseFarmospOverview = (overviewResponse: IOverviewFarmospWithPermissions): IOverviewFarmospWithPermissions => {
    if (!!overviewResponse && Array.isArray(overviewResponse.overviewTableRows)) {
        const { permissionSet } = overviewResponse;
        return {
            overviewTableRows: overviewResponse.overviewTableRows,
            permissionSet: { ...permissionSet },
        };
    } else {
        return {
            permissionSet: {
                canDownloadFiles: false,
                canDelete: false,
                canConfirm: false,
                canUpload: false,
                canSend: false,
                canDowloadLogSynthetic: false,
                canViewIconFiles: false,
                canExportOverview: false,
            },
            overviewTableRows: [],
        };
    }
};

export const deserializeUseSpsOverview = (overviewResponse: IOverviewSpsWithPermissions): IOverviewSpsWithPermissions => {
    if (!!overviewResponse && Array.isArray(overviewResponse.overviewTableRows)) {
        const { permissionSet } = overviewResponse;
        return {
            overviewTableRows: overviewResponse.overviewTableRows,
            permissionSet: { ...permissionSet },
        };
    } else {
        return {
            permissionSet: {
                canDownloadFiles: false,
                canDelete: false,
                canConfirm: false,
                canUpload: false,
                canSend: false,
                canDowloadLogSynthetic: false,
                canViewIconFiles: false,
                canExportOverview: false,
            },
            overviewTableRows: [],
        };
    }
};

export const deserializeUseDiabOverview = (overviewResponse: IOverviewDiabWithPermissions): IOverviewDiabWithPermissions => {
    if (
        !!overviewResponse &&
        overviewResponse.overviewTableRows?.order1?.orderList &&
        overviewResponse.overviewTableRows?.order2?.orderList &&
        overviewResponse.overviewTableRows?.order3?.orderList &&
        overviewResponse.overviewTableRows?.order4?.orderList &&
        Array.isArray(overviewResponse.overviewTableRows.order1.orderList) &&
        Array.isArray(overviewResponse.overviewTableRows.order2.orderList) &&
        Array.isArray(overviewResponse.overviewTableRows.order3.orderList) &&
        Array.isArray(overviewResponse.overviewTableRows.order4.orderList)
    ) {
        const { permissionSet } = overviewResponse;
        return {
            overviewTableRows: overviewResponse.overviewTableRows,
            permissionSet: { ...permissionSet },
        };
    } else {
        return {
            permissionSet: {
                canDownloadFiles: false,
                canDelete: false,
                canConfirm: false,
                canUpload: false,
                canSend: false,
                canDowloadLogSynthetic: false,
                canViewIconFiles: false,
                canExportOverview: false,
            },
            overviewTableRows: {
                order1: {
                    name: '',
                    orderList: [[], []],
                },
                order2: {
                    name: '',
                    orderList: [[], []],
                },
                order3: {
                    name: '',
                    orderList: [[], []],
                },
                order4: {
                    name: '',
                    orderList: [[], []],
                },
            },
        };
    }
};

export const deserializeUse118Overview = (overviewResponse: IOverview118WithPermissions): IOverview118WithPermissions => {
    if (!!overviewResponse && overviewResponse.overviewTableRows && Array.isArray(overviewResponse.overviewTableRows)) {
        const { permissionSet } = overviewResponse;
        return {
            overviewTableRows: overviewResponse.overviewTableRows,
            permissionSet: { ...permissionSet },
        };
    } else {
        return {
            permissionSet: {
                canDownloadFiles: false,
                canDelete: false,
                canConfirm: false,
                canUpload: false,
                canSend: false,
                canDowloadLogSynthetic: false,
                canViewIconFiles: false,
                canExportOverview: false,
            },
            overviewTableRows: [],
        };
    }
};

export const deserializeUseSpaOverview = (overviewResponse: IOverviewSpaWithPermissions): IOverviewSpaWithPermissions => {
    if (!!overviewResponse && overviewResponse.overviewTableRows && Array.isArray(overviewResponse.overviewTableRows)) {
        const { permissionSet } = overviewResponse;
        return {
            overviewTableRows: overviewResponse.overviewTableRows,
            permissionSet: { ...permissionSet },
        };
    } else {
        return {
            permissionSet: {
                canDownloadFiles: false,
                canDelete: false,
                canConfirm: false,
                canUpload: false,
                canSend: false,
                canDowloadLogSynthetic: false,
                canViewIconFiles: false,
                canExportOverview: false,
            },
            overviewTableRows: [],
        };
    }
};

export const deserializeUseLaboOverview = (overviewResponse: IOverviewLaboWithPermissions): IOverviewLaboWithPermissions => {
    if (!!overviewResponse && overviewResponse.overviewTableRows && Array.isArray(overviewResponse.overviewTableRows)) {
        const { permissionSet } = overviewResponse;
        return {
            overviewTableRows: overviewResponse.overviewTableRows,
            permissionSet: { ...permissionSet },
        };
    } else {
        return {
            permissionSet: {
                canDownloadFiles: false,
                canDelete: false,
                canConfirm: false,
                canUpload: false,
                canSend: false,
                canDowloadLogSynthetic: false,
                canViewIconFiles: false,
                canExportOverview: false,
            },
            overviewTableRows: [],
        };
    }
};

export const deserializeUseFarmterOverview = (overviewResponse: IOverviewFarmterWithPermissions): IOverviewFarmterWithPermissions => {
    if (!!overviewResponse && Array.isArray(overviewResponse.overviewTableRows)) {
        const { permissionSet } = overviewResponse;
        return {
            overviewTableRows: overviewResponse.overviewTableRows,
            permissionSet: { ...permissionSet },
        };
    } else {
        return {
            permissionSet: {
                canDownloadFiles: false,
                canDelete: false,
                canConfirm: false,
                canUpload: false,
                canSend: false,
                canDowloadLogSynthetic: false,
                canViewIconFiles: false,
                canExportOverview: false,
            },
            overviewTableRows: [],
        };
    }
};
