import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { ExlBtnWrapper, OverlayChild } from '../../style';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import ExcelSvg from '../Svg/ExcelSvg';
import { IOverviewFarmter } from '../../model';
import { IconBtn } from '../Buttons/Buttons';
import { getCurrentMonthAndYear } from '../../utils';
import { t } from 'i18next';

interface IExportExcelFarmterProps {
    currentYearRecs: IOverviewFarmter[];
    lastYearRecs: IOverviewFarmter[];
}

const ExportExcelFarmter = (props: IExportExcelFarmterProps) => {
    const { currentYearRecs, lastYearRecs } = props;
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let fileName = 'FARMTER-Overview';

    const exportToExcel = () => {
        const emptyRow = () => {
            return {
                [t('genericOverview.clmYear')]: '',
                [t('genericOverview.structureAuthority')]: '',
                [t('genericOverview.measureDescription')]: '',
                [t('months.0')]: '',
                [t('months.1')]: '',
                [t('months.2')]: '',
                [t('months.3')]: '',
                [t('months.4')]: '',
                [t('months.5')]: '',
                [t('months.6')]: '',
                [t('months.7')]: '',
                [t('months.8')]: '',
                [t('months.9')]: '',
                [t('months.10')]: '',
                [t('months.11')]: '',
            };
        };

        const titleRow = () => {
            return {
                [t('genericOverview.clmYear')]: t('genericOverview.clmYear'),
                [t('genericOverview.structureAuthority')]: t('genericOverview.structureAuthority'),
                [t('genericOverview.measureDescription')]: t('genericOverview.measureDescription'),
                [t('months.0')]: t('months.0'),
                [t('months.1')]: t('months.1'),
                [t('months.2')]: t('months.2'),
                [t('months.3')]: t('months.3'),
                [t('months.4')]: t('months.4'),
                [t('months.5')]: t('months.5'),
                [t('months.6')]: t('months.6'),
                [t('months.7')]: t('months.7'),
                [t('months.8')]: t('months.8'),
                [t('months.9')]: t('months.9'),
                [t('months.10')]: t('months.10'),
                [t('months.11')]: t('months.11'),
            };
        };

        const mapRow = (row: IOverviewFarmter, year: string, measureDescription: string, dataField: string) => ({
            [t('genericOverview.clmYear')]: year,
            [t('genericOverview.structureAuthority')]: row.institute,
            [t('genericOverview.measureDescription')]: t(measureDescription),
            [t('months.0')]: row.jan[dataField],
            [t('months.1')]: row.feb[dataField],
            [t('months.2')]: row.mar[dataField],
            [t('months.3')]: row.apr[dataField],
            [t('months.4')]: row.may[dataField],
            [t('months.5')]: row.jun[dataField],
            [t('months.6')]: row.jul[dataField],
            [t('months.7')]: row.aug[dataField],
            [t('months.8')]: row.sep[dataField],
            [t('months.9')]: row.oct[dataField],
            [t('months.10')]: row.nov[dataField],
            [t('months.11')]: row.dec[dataField],
        });

        let finalRows: {
            [x: string]: string | number;
        }[] = [];

        const currentYearLabel = currentYearRecs[0]?.year ?? getCurrentMonthAndYear().currentYear;
        const lastYearLabel = lastYearRecs[0]?.year ?? getCurrentMonthAndYear().currentYear;

        if (currentYearRecs.length > 0) {
            finalRows = [
                ...currentYearRecs.map(item => mapRow(item, `${currentYearLabel}`, 'genericOverview.numOfRecipes', 'nrRicette')),
                ...currentYearRecs.map(item => mapRow(item, `${currentYearLabel}`, 'genericOverview.totalAmount', 'importoTotale')),
                ...currentYearRecs.map(item => mapRow(item, `${currentYearLabel}`, 'genericOverview.totalFixedFee', 'totQuotaFissa')),
            ];

            if (lastYearRecs.length > 0) {
                finalRows = finalRows.concat([
                    emptyRow(),
                    titleRow(),
                    ...lastYearRecs.map(item => mapRow(item, `${lastYearLabel}`, 'genericOverview.numOfRecipes', 'nrRicette')),
                    ...lastYearRecs.map(item => mapRow(item, `${lastYearLabel}`, 'genericOverview.totalAmount', 'importoTotale')),
                    ...lastYearRecs.map(item => mapRow(item, `${lastYearLabel}`, 'genericOverview.totalFixedFee', 'totQuotaFissa')),
                ]);
            }
        } else if (lastYearRecs.length > 0) {
            finalRows = [
                ...lastYearRecs.map(item => mapRow(item, `${lastYearLabel}`, 'genericOverview.numOfRecipes', 'nrRicette')),
                ...lastYearRecs.map(item => mapRow(item, `${lastYearLabel}`, 'genericOverview.totalAmount', 'importoTotale')),
                ...lastYearRecs.map(item => mapRow(item, `${lastYearLabel}`, 'genericOverview.totalFixedFee', 'totQuotaFissa')),
            ];
        }

        const ws = XLSX.utils.json_to_sheet(finalRows.flat());
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };
    return (
        <ExlBtnWrapper>
            <OverlayTrigger transition={false} i18nIsDynamicList placement='top' overlay={<Tooltip>{t('generic.downloadExcel', { flow: 'FARMTER' })}</Tooltip>}>
                <OverlayChild>
                    <IconBtn withOutline onClick={exportToExcel}>
                        <ExcelSvg />
                    </IconBtn>
                </OverlayChild>
            </OverlayTrigger>
        </ExlBtnWrapper>
    );
};

export default ExportExcelFarmter;
