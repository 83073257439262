import BreadCrumb from '../Breadcrumb/BreadCrumb';
import { EFlowType } from '../../model/enum';
import Overview118 from './Overview118';
import OverviewDiab from './OverviewDiab';
import OverviewDm from './OverviewDm';
import OverviewFarm from './OverviewFarm';
import OverviewFarmosp from './OverviewFarmosp';
import OverviewFarmter from './OverviewFarmter';
import OverviewFim from './OverviewFim';
import OverviewLabo from './OverviewLabo';
import OverviewPatology from './OverviewPatology';
import OverviewRecovery from './OverviewRecovery';
import OverviewSpa from './OverviewSpa';
import OverviewSps from './OverviewSps';
import { t } from 'i18next';

interface IOverviewFactory {
    flowType: EFlowType;
}

const OverviewFactory = (props: IOverviewFactory) => {
    const { flowType } = props;
    switch (flowType) {
        case EFlowType.Fim.toUpperCase():
            return <OverviewFim />;
        case EFlowType.Sps.toUpperCase():
            return <OverviewSps />;
        case EFlowType.Farmosp.toUpperCase():
            return <OverviewFarmosp />;
        case EFlowType.Dm.toUpperCase():
            return <OverviewDm />;
        case EFlowType.Siar.toUpperCase():
        case EFlowType.Cedp.toUpperCase():
        case EFlowType.Sderia.toUpperCase():
        case EFlowType.Hosp.toUpperCase():
            return <OverviewRecovery flow={flowType} />;
        case EFlowType.Rpa.toUpperCase():
        case EFlowType.Rpg.toUpperCase():
            return <OverviewPatology flow={flowType} />;
        case EFlowType.Farm.toUpperCase():
            return <OverviewFarm />;
        case EFlowType.Diab.toUpperCase():
            return <OverviewDiab />;
        case EFlowType.X.toUpperCase():
            return <Overview118 />;
        case EFlowType.Labo.toUpperCase():
            return <OverviewLabo />;
        case EFlowType.Spa.toUpperCase():
            return <OverviewSpa />;
        case EFlowType.Farmter.toUpperCase():
            return <OverviewFarmter />;
        default:
            return <BreadCrumb paths={[{ nameToShow: t('routes.overview'), url: 'overview' }]} />;
    }
};

export default OverviewFactory;
