import { Container, ContainerNoGap, CustomDivOverview, RightTd, RightTh, TableRow } from '../../style';

import BreadCrumb from '../Breadcrumb/BreadCrumb';
import ExportExcelFarmter from '../ExportExcel/ExportExcelFarmter';
import GenericError from '../GenericError/GenericError';
import { IOverviewFarmter } from '../../model';
import Loading from '../Loading/Loading';
import { LoggedUserContext } from '../../App';
import { Table } from 'react-bootstrap';
import { get } from 'lodash';
import { getCurrentMonthAndYear } from '../../utils';
import { t } from 'i18next';
import { useContext } from 'react';
import { useFarmterOverview } from '../../api/fetch';

interface IOverviewFarmterProps {}

const OverviewFarmter = (props: IOverviewFarmterProps) => {
    const { loggedUser } = useContext(LoggedUserContext);
    const { overview, isError, isLoading } = useFarmterOverview(loggedUser.userId);

    const overviewTableRows = get(overview, 'overviewTableRows', [[]] as IOverviewFarmter[][]);
    const lastYearBlock = overviewTableRows[0] || [];
    const currentYearBlock = overviewTableRows[1] || [];

    const sortByInstitute = (observations: IOverviewFarmter[]) => {
        const dataToBeSorted = [...observations];
        if (dataToBeSorted && dataToBeSorted.length > 0) {
            return dataToBeSorted.sort((a, b) => {
                const nameA = a.institute.toUpperCase();
                const nameB = b.institute.toUpperCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
            });
        }
        return dataToBeSorted;
    };

    const currentYearRecs = sortByInstitute(currentYearBlock);
    const lastYearRecs = sortByInstitute(lastYearBlock);

    const clms = [
        { name: t('genericOverview.structureAuthority'), id: 1 },
        { name: t('genericOverview.measureDescription'), id: 2 },
        { name: t('months.0'), id: 3 },
        { name: t('months.1'), id: 4 },
        { name: t('months.2'), id: 5 },
        { name: t('months.3'), id: 6 },
        { name: t('months.4'), id: 7 },
        { name: t('months.5'), id: 8 },
        { name: t('months.6'), id: 9 },
        { name: t('months.7'), id: 10 },
        { name: t('months.8'), id: 11 },
        { name: t('months.9'), id: 12 },
        { name: t('months.10'), id: 13 },
        { name: t('months.11'), id: 14 },
    ];

    return (
        <Container>
            <BreadCrumb paths={[{ nameToShow: t('routes.overview'), url: 'overview-farmter' }]} />
            {isLoading && <Loading />}
            {isError && <GenericError />}
            <ExportExcelFarmter currentYearRecs={currentYearRecs} lastYearRecs={lastYearRecs} />
            {currentYearRecs.length > 0 && (
                <ContainerNoGap>
                    <CustomDivOverview>{<b>{currentYearRecs[0]?.year ?? getCurrentMonthAndYear().currentYear}</b>}</CustomDivOverview>
                    <div className='tableFixHead'>
                        <Table size='xl'>
                            {
                                <thead className='sticky'>
                                    <tr className='sticky'>
                                        {clms.map((item, index) =>
                                            index === 0 ? (
                                                <th key={item.id} className='table-dark'>
                                                    {item.name}
                                                </th>
                                            ) : (
                                                <RightTh key={item.id} className='table-dark'>
                                                    {item.name}
                                                </RightTh>
                                            ),
                                        )}
                                    </tr>
                                </thead>
                            }
                            <tbody>
                                {currentYearRecs.map((data, index) => (
                                    <TableRow key={`recipes-current-${index}`}>
                                        <td>{data.institute}</td>
                                        <RightTd>{t('genericOverview.numOfRecipes')}</RightTd>
                                        <RightTd>{data.jan.nrRicette.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.feb.nrRicette.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.mar.nrRicette.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.apr.nrRicette.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.may.nrRicette.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.jun.nrRicette.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.jul.nrRicette.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.aug.nrRicette.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.sep.nrRicette.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.oct.nrRicette.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.nov.nrRicette.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.dec.nrRicette.toLocaleString('de-DE')}</RightTd>
                                    </TableRow>
                                ))}
                                {currentYearRecs.map((data, index) => (
                                    <TableRow key={`amount-current-${index}`}>
                                        <td>{data.institute}</td>
                                        <RightTd>{t('genericOverview.totalAmount')}</RightTd>
                                        <RightTd>{data.jan.importoTotale.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.feb.importoTotale.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.mar.importoTotale.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.apr.importoTotale.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.may.importoTotale.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.jun.importoTotale.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.jul.importoTotale.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.aug.importoTotale.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.sep.importoTotale.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.oct.importoTotale.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.nov.importoTotale.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.dec.importoTotale.toLocaleString('de-DE')}</RightTd>
                                    </TableRow>
                                ))}
                                {currentYearRecs.map((data, index) => (
                                    <TableRow key={`fee-current-${index}`}>
                                        <td>{data.institute}</td>
                                        <RightTd>{t('genericOverview.totalFixedFee')}</RightTd>
                                        <RightTd>{data.jan.totQuotaFissa.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.feb.totQuotaFissa.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.mar.totQuotaFissa.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.apr.totQuotaFissa.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.may.totQuotaFissa.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.jun.totQuotaFissa.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.jul.totQuotaFissa.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.aug.totQuotaFissa.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.sep.totQuotaFissa.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.oct.totQuotaFissa.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.nov.totQuotaFissa.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.dec.totQuotaFissa.toLocaleString('de-DE')}</RightTd>
                                    </TableRow>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </ContainerNoGap>
            )}
            {lastYearRecs.length > 0 && (
                <ContainerNoGap>
                    <CustomDivOverview>{<b>{lastYearRecs[0]?.year ?? getCurrentMonthAndYear().currentYear - 1}</b>}</CustomDivOverview>
                    <div className='tableFixHead'>
                        <Table size='xl'>
                            <thead className='sticky'>
                                <tr className='sticky'>
                                    {clms.map((item, index) =>
                                        index === 0 ? (
                                            <th key={item.id} className='table-dark'>
                                                {item.name}
                                            </th>
                                        ) : (
                                            <RightTh key={item.id} className='table-dark'>
                                                {item.name}
                                            </RightTh>
                                        ),
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {lastYearRecs.map((data, index) => (
                                    <TableRow key={`recipes-last-${index}`}>
                                        <td>{data.institute}</td>
                                        <RightTd>{t('genericOverview.numOfRecipes')}</RightTd>
                                        <RightTd>{data.jan.nrRicette.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.feb.nrRicette.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.mar.nrRicette.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.apr.nrRicette.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.may.nrRicette.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.jun.nrRicette.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.jul.nrRicette.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.aug.nrRicette.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.sep.nrRicette.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.oct.nrRicette.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.nov.nrRicette.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.dec.nrRicette.toLocaleString('de-DE')}</RightTd>
                                    </TableRow>
                                ))}
                                {lastYearRecs.map((data, index) => (
                                    <TableRow key={`amount-last-${index}`}>
                                        <td>{data.institute}</td>
                                        <RightTd>{t('genericOverview.totalAmount')}</RightTd>
                                        <RightTd>{data.jan.importoTotale.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.feb.importoTotale.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.mar.importoTotale.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.apr.importoTotale.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.may.importoTotale.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.jun.importoTotale.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.jul.importoTotale.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.aug.importoTotale.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.sep.importoTotale.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.oct.importoTotale.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.nov.importoTotale.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.dec.importoTotale.toLocaleString('de-DE')}</RightTd>
                                    </TableRow>
                                ))}
                                {lastYearRecs.map((data, index) => (
                                    <TableRow key={`fee-last-${index}`}>
                                        <td>{data.institute}</td>
                                        <RightTd>{t('genericOverview.totalFixedFee')}</RightTd>
                                        <RightTd>{data.jan.totQuotaFissa.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.feb.totQuotaFissa.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.mar.totQuotaFissa.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.apr.totQuotaFissa.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.may.totQuotaFissa.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.jun.totQuotaFissa.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.jul.totQuotaFissa.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.aug.totQuotaFissa.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.sep.totQuotaFissa.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.oct.totQuotaFissa.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.nov.totQuotaFissa.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.dec.totQuotaFissa.toLocaleString('de-DE')}</RightTd>
                                    </TableRow>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </ContainerNoGap>
            )}
        </Container>
    );
};

export default OverviewFarmter;
